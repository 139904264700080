import React, { useState, useEffect } from "react";
import "./App.css";
import ArtifactList from "./components/ArtifactList";
import ReleaseDropdown from "./components/ReleaseDropdown";
import logo from "./logo.png";
import { ArtifactResource, ReleaseResource } from "./api/models";
import ApiService from "./api/KeygenApi";
import vscodeIcon from "./icons/vscode.svg";
import allowOsx from "./icons/osx-allow.png";
import proxyIcon from "./icons/proxy.svg";
import CodeSnippet from "./components/CodeSnippet";
const licenseKey = window.location.hash.split("#")[1];
const api = new ApiService("52c049c7-8aea-46d9-ba83-df77baf1a006", licenseKey);
var releasess = api.getReleases();
const App: React.FC = () => {
  const [releases, setReleases] = useState<ReleaseResource[]>([]);
  const [selectedRelease, setSelectedRelease] = useState<
    ReleaseResource | undefined
  >(undefined);
  const [artifacts, setArtifacts] = useState<ArtifactResource[]>([]);

  useEffect(() => {
    if (!releases || releases.length === 0)
      releasess.then((data) => {
        setReleases(data.data);
        setSelectedRelease(data.data[0]);
      });
  }, []);

  useEffect(() => {
    if (selectedRelease) {
      api
        .getArtifacts(selectedRelease.id)
        .then((data) => setArtifacts(data.data));
    }
  }, [selectedRelease]);

  const handleSelectRelease = (release: ReleaseResource) => {
    setSelectedRelease(release);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img className="logo" src={logo} alt="Logo" />
          <h1>jswzl builds</h1>
        </div>
        <ReleaseDropdown
          releases={releases ?? []}
          onSelectRelease={handleSelectRelease}
        />
      </header>
      <main>
        {selectedRelease ? (
          <table>
            <td>
              <ArtifactList
                title="1. Analysis host"
                description={
                  <span>
                    The main host for jswzl, which does all of the analysis.
                    <ul>1. Download the applicable file for your platform</ul>
                    <ul>2. Extract it somewhere</ul>{" "}
                    <ul>
                      3. (Linux and OS X) Run `chmod +x jswzl.Server` to make
                      the binary executable
                    </ul>
                    <ul>
                      4. (OS X ARM64) If you are on OS X with an ARM64 build for
                      Apple Silicon, you will need to sign the binary with
                      `codesign -s - jswzl.Server`.
                    </ul>
                    <ul>
                      5. (OS X) Execute the binary. Then go to `System
                      Preferences &gt; Security & Privacy`, and press "Allow
                      Anyway"
                      <br />
                      <img src={allowOsx} width={"100%"} />
                    </ul>
                    <ul>
                      6. Create a file called `license.lic` in the folder you
                      extracted the host to, and enter this license key:
                    </ul>
                    <CodeSnippet text={licenseKey}></CodeSnippet>
                  </span>
                }
                artifacts={artifacts.filter(
                  (artifact) => artifact.attributes.arch === "jswzl"
                )}
                release={selectedRelease}
                logo={logo}
              ></ArtifactList>
            </td>
            <td>
              <ArtifactList
                title="2. Proxy Plugin (Required)"
                description="Plugins for Burp Suite and Caido, which allows you to send requests to the jswzl host application"
                artifacts={artifacts.filter(
                  (artifact) =>
                    artifact.attributes.arch === "burp" ||
                    artifact.attributes.arch === "caido"
                )}
                release={selectedRelease}
                logo={proxyIcon}
              ></ArtifactList>
            </td>{" "}
            <td>
              <ArtifactList
                title="3. VS Code extension (Required)"
                description="Extension for VS Code, which enables you to view results from the jswzl host application"
                artifacts={artifacts.filter(
                  (artifact) => artifact.attributes.arch === "vscode"
                )}
                release={selectedRelease}
                logo={vscodeIcon}
              ></ArtifactList>
            </td>
          </table>
        ) : (
          <p>Please select a release</p>
        )}
      </main>
    </div>
  );
};

export default App;
